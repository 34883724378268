/*
**************************************************************************************************************
											                      R&D TECH LIMITED
==============================================================================================================
The contents of this file ("Software") is the property of R&D Tech Limited ("R&D Tech") and is protected by
copyright and other intellectual property laws and treaties.

R&D Tech owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of copies of the
Software, redistribution of the Software and creation of derived works from the Software (and the
redistribution thereof) are subject to the terms of a license agreement issued by R&D Tech.

If the person in possession of the Software has not entered into a license agreement with R&D Tech, then any
use of the Software for any purpose whatsoever is strictly prohibited.
==============================================================================================================

**************************************************************************************************************
					                                  	Amendments
==============================================================================================================
  M005 : 10 Oct 2022 : Nick
    * M-4210: Fix the campaign approve endpoints
	M004 : 01 Jun 2021 : Nick
    * M-3426: Add markets and stocks coverage menu under latest research
	M003 : 21 Apr 2021 : Aman
    * M-3347: Allow upload of Our People photos to be uploaded via Team Management page
	M002 : 24 Nov 2020 : Aman
    * M-3069: Allow arranging the order of the homepage sliders
	M001 : 02 Sep 2020 : Nicholas
    * M-3008: Increase the number of homepage banners
**************************************************************************************************************
*/

export const uploadHeaderImage = obj => ({
  type: 'UPLOAD_HEADER_IMAGE',
  obj,
});

export const uploadHeaderImageSuccess = (data, obj) => ({
  type: 'UPLOAD_HEADER_IMAGE_SUCCESS',
  data,
  obj,
});

export const uploadHeaderImageFail = (error, obj) => ({
  type: 'UPLOAD_HEADER_IMAGE_FAILURE',
  error,
  obj,
});
//====================M003==========================
export const uploadAnalystImage = obj => ({
  type: 'UPLOAD_ANALYST_IMAGE',
  obj,
});

export const uploadAnalystImageSuccess = (data, obj) => ({
  type: 'UPLOAD_ANALYST_IMAGE_SUCCESS',
  data,
  obj,
});

export const uploadAnalystImageFail = (error, obj) => ({
  type: 'UPLOAD_ANALYST_IMAGE_FAILURE',
  error,
  obj,
});
//====================M003==========================
export const getHeaderImage = obj => ({
  type: 'GET_HEADER_IMAGE',
  obj,
});

export const getHeaderImageSuccess = (data, obj) => ({
  type: 'GET_HEADER_IMAGE_SUCCESS',
  data,
  obj,
});

export const getHeaderImageFail = (error, obj) => ({
  type: 'GET_HEADER_IMAGE_FAILURE',
  error,
  obj,
});

export const setHeaderBannerDefault = obj => ({
  type: 'SET_HEADER_IMAGE_DEFAULT',
  obj,
});

export const setHeaderBannerDefaultSuccess = data => ({
  type: 'SET_HEADER_IMAGE_DEFAULT_SUCCESS',
  data,
});

export const setHeaderBannerDefaultFail = error => ({
  type: 'SET_HEADER_IMAGE_DEFAULT_FAIL',
  error,
});

export const setComponentLayout = obj => ({
  type: 'SET_LAYOUT',
  obj,
});

export const showSuggestionBoxDropDown = obj => ({
  type: 'SET_SUGGESTION_BOX',
  obj,
});

export const setSliderState = obj => ({
  type: 'SET_SLIDER_STATE',
  obj,
});

export const saveSliderSettings = obj => ({
  type: 'SAVE_SLIDER_SETTINGS',
  obj,
});

export const saveSliderSettingsSuccess = (data, obj) => ({
  type: 'SAVE_SLIDER_SETTINGS_SUCCESS',
  data,
  obj,
});

export const saveSliderSettingsFail = (error, obj) => ({
  type: 'SAVE_SLIDER_SETTINGS_FAILURE',
  error,
  obj,
});
//====================M002==========================
export const saveSliderOrder = (obj, history) => ({
  type: 'SAVE_SLIDER_ORDER',
  obj,
  history,
});

export const saveSliderOrderSuccess = (data, obj) => ({
  type: 'SAVE_SLIDER_ORDER_SUCCESS',
  data,
  obj,
});

export const saveSliderOrderFail = (error, obj) => ({
  type: 'SAVE_SLIDER_ORDER_FAILURE',
  error,
  obj,
});
//====================M002==========================
//====================M001==========================
export const setSlider = obj => ({
  type: 'SET_NEW_SLIDER',
  obj,
});
//====================M001==========================
export const setActiveTabState = index => ({
  type: 'SET_ACTIVE_TAB_STATE',
  index,
});

export const saveFavourites = obj => ({
  type: 'SET_FAVOURITES',
  obj,
});

export const saveFavouritesSuccess = (data, obj) => ({
  type: 'SET_FAVOURITES_SUCCESS',
  data,
  obj,
});

export const saveFavouritesFail = (error, obj) => ({
  type: 'SET_FAVOURITES_FAILURE',
  error,
  obj,
});

export const getFavourites = obj => ({
  type: 'GET_FAVOURITES',
  obj,
});

export const getFavouritesSuccess = (data, obj) => ({
  type: 'GET_FAVOURITES_SUCCESS',
  data,
  obj,
});

export const getFavouritesFail = (error, obj) => ({
  type: 'GET_FAVOURITES_FAILURE',
  error,
  obj,
});

export const createPost = obj => ({
  type: 'CREATE_POST',
  obj,
});
export const createPostSuccess = obj => ({
  type: 'CREATE_POST_SUCCESS',
  obj,
});
export const createPostFail = obj => ({
  type: 'CREATE_POST_FAILURE',
  obj,
});
export const getPosts = obj => ({
  type: 'GET_POSTS',
  obj,
});
export const getPostsSuccess = obj => ({
  type: 'GET_POSTS_SUCCESS',
  obj,
});
export const getPostsFail = obj => ({
  type: 'GET_POSTS_FAILURE',
  obj,
});
export const deletePost = obj => ({
  type: 'DELETE_POST',
  obj,
});
export const deletePostSuccess = obj => ({
  type: 'DELETE_POST_SUCCESS',
  obj,
});
export const deletePostFail = obj => ({
  type: 'DELETE_POST_FAILURE',
  obj,
});
export const updatePost = obj => ({
  type: 'UPDATE_POST',
  obj,
});
export const updatePostSuccess = obj => ({
  type: 'UPDATE_POST_SUCCESS',
  obj,
});
export const updatePostFail = obj => ({
  type: 'UPDATE_POST_FAILURE',
  obj,
});
export const showEditPopUp = obj => ({
  type: 'SHOW_EDIT_POST',
  obj,
});
export const publishPost = obj => ({
  type: 'PUBLISH_POST',
  obj,
});
export const publishPostSuccess = obj => ({
  type: 'PUBLISH_POST_SUCCESS',
  obj,
});
export const publishPostFail = obj => ({
  type: 'PUBLISH_POST_FAIL',
  obj,
});
export const unPublishPost = obj => ({
  type: 'UNPUBLISH_POST',
  obj,
});
export const unPublishPostSuccess = obj => ({
  type: 'UNPUBLISH_POST_SUCCESS',
  obj,
});
export const unPublishPostFail = obj => ({
  type: 'UNPUBLISH_POST_FAIL',
  obj,
});
export const setFavourite = obj => ({
  type: 'SET_NEW_KEYWORD',
  obj,
});
export const deleteKeyword = obj => ({
  type: 'DELETE_KEYWORD',
  obj,
});
export const deleteKeywordSuccess = obj => ({
  type: 'DELETE_KEYWORD_SUCCESS',
  obj,
});
export const deleteKeywordFail = obj => ({
  type: 'DELETE_KEYWORD_FAIL',
  obj,
});
export const saveBlogResearch = obj => ({
  type: 'SAVE_BLOG_RESEARCH',
  obj,
});
export const saveBlogResearchSuccess = obj => ({
  type: 'SAVE_BLOG_RESEARCH_SUCCESS',
  obj,
});
export const saveBlogResearchFail = obj => ({
  type: 'SAVE_BLOG_RESEARCH_FAIL',
  obj,
});
export const getEventsApprovalEvents = obj => ({
  type: 'GET_EVENTS_APPROVAL_EVENTS',
  obj,
});
export const getEventsApprovalEventsSuccess = obj => ({
  type: 'GET_EVENTS_APPROVAL_EVENTS_SUCCESS',
  obj,
});
export const getEventsApprovalEventsFail = obj => ({
  type: 'GET_EVENTS_APPROVAL_EVENTS_FAIL',
  obj,
});
//==================M005=======[===============
export const getEventApprovalList = (cmpg_id, evt_id) => ({
  type: 'GET_EVENT_APPROVAL_LIST',
  cmpg_id,
  evt_id,
});
//==================M005=======[===============
export const getEventApprovalListSuccess = obj => ({
  type: 'GET_EVENT_APPROVAL_LIST_SUCCESS',
  obj,
});
export const getEventApprovalListFail = obj => ({
  type: 'GET_EVENT_APPROVAL_LIST_FAIL',
  obj,
});
export const updateBlogsList = obj => ({
  type: 'UPDATE_BLOGS_LIST',
  obj,
});

export const updateBlogsListSuccess = (data, obj) => ({
  type: 'UPDATE_BLOGS_LIST_SUCCESS',
  data,
  obj,
});

export const updateBlogsListFail = (error, obj) => ({
  type: 'UPDATE_BLOGS_LIST_FAILURE',
  error,
  obj,
});
//==================M005=======[===============
export const approveEvents = (cmpg_id, evt_id, approveList) => ({
  type: 'APPROVE_EVENTS',
  cmpg_id,
  evt_id,
  approveList,
});
//==================M005=======]===============
export const approveEventsSuccess = data => ({
  type: 'APPROVE_EVENTS_SUCCESS',
  data,
});

export const approveEventsFail = error => ({
  type: 'APPROVE_EVENTS_FAIL',
  error,
});

//====================M004==========[================
export const getNavItems = obj => ({
  type: 'GET_NAVITEMS',
  obj,
});

export const getNavItemsSuccess = (data, obj) => ({
  type: 'GET_NAVITEMS_SUCCESS',
  data,
  obj,
});

export const getNavItemsFail = (error, obj) => ({
  type: 'GET_NAVITEMS_FAILURE',
  error,
  obj,
});

export const editBiography = obj => ({
  type: 'EDIT_BIOGRAPHY',
  obj,
});

export const editBiographySuccess = (data, obj) => ({
  type: 'EDIT_BIOGRAPHY_SUCCESS',
  data,
  obj,
});

export const editBiographyFail = (error, obj) => ({
  type: 'EDIT_BIOGRAPHY_FAILURE',
  error,
  obj,
});
//====================M004===========]===============

export const getParameter = obj => ({
  type: 'GET_PARAMETER',
  obj,
});

export const getParameterSuccess = data => ({
  type: 'GET_PARAMETER_SUCCESS',
  data,
});

export const getParameterFail = error => ({
  type: 'GET_PARAMETER_FAILURE',
  error,
});

export const getCustomPageParameter = obj => ({
  type: 'GET_CUSTOMPAGE_PARAMETER',
  obj,
});

export const getCustomPageParameterSuccess = data => ({
  type: 'GET_CUSTOMPAGE_PARAMETER_SUCCESS',
  data,
});

export const getCustomPageParameterFail = error => ({
  type: 'GET_CUSTOMPAGE_PARAMETER_FAILURE',
  error,
});
export const getMatrixData = obj => ({
  type: 'GET_MATRIX_DATA',
  obj,
});

export const getMatrixDataSuccess = data => ({
  type: 'GET_MATRIX_DATA_SUCCESS',
  data,
});

export const getMatrixDataFail = error => ({
  type: 'GET_MATRIX_DATA_FAILURE',
  error,
});
